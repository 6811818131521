/**
 * @ External Dependecies
 */
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { unregister } from "./registerServiceWorker";
import "./styles/reset-styles";
import "./styles/base-styles";
import "./styles/wizard-styles";
import "./styles/datepicker-styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "styles/spinner.css";
import theme from "./styles/theme";
import store from "./store/store";
import { googleAnalysticsCode } from "constants/common";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import App from "./components/app/App";
import { appEnv } from "utilities/API.js";

import { PostHogProvider } from "posthog-js/react";

const options = {
  // debug: appEnv === "DEV",
  api_host: "https://app.posthog.com",
  session_recording: {
    maskNetworkRequestFn: (request) => {
      return request;
    },
  },
};

const history = createBrowserHistory();

if (appEnv === "PROD") {
  ReactGA.initialize(googleAnalysticsCode);
  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
}

{
  /* <PostHogProvider apiKey="phc_s4TgQMxLCBzfVTaQWUT9grv7mEN4yiXSzlpfD9MqV3w" options={options}> */
}
{
  /* </PostHogProvider> */
}
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </Provider>,

  document.getElementById("root")
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

// Opting out for now
// registerServiceWorker();
unregister();

import { createAction } from "redux-actions";
import { orderBy } from "lodash";
import { COMMON_MESSAGES } from "constants/messages";
import {
  callGetAssessmentStudentDashboardAPI,
  callGetStudentAttemptDashboardAPI,
} from "services/facultyDashboard";

import { getUserCurrentAccount } from "utilities/commonFunctions";
import { isEmpty, pick } from "ramda";
import {
  callGetDeploymentDashboardAPI,
  getFacultyDeploymentAssessmentStats,
  getFacultyDeploymentStudentList,
} from "services/facultyDashboard";
import { OPERATIONS, barChartColors as colors } from "constants/common";
import { callGetDeploymentsAPI } from "services/deployments";

export const request = createAction("REQUEST_FACULTY_DASHBOARD");
export const requestDashboard = createAction("REQUEST_FACULTY_DASHBOARD_DATA");
export const reject = createAction("REJECT_FACULTY_DASHBOARD");
export const listDeploymentDashboardData = createAction("LIST_DEPLOYMENT_DASHBOARD_DATA");
export const facultyDeploymentAssessmentStudentList = createAction(
  "DEPLOYMENT_ASSESSMENT_STUDENT_LIST"
);
export const assessmentStats = createAction("ASSESSMENT_STATS");

export const listAssessmentStudentDashboardData = createAction(
  "LIST_ASSESSMENT_STUDENT_DASHBOARD_DATA"
);
export const clearAssessmentStudentDashboardData = createAction(
  "CLEAR_ASSESSMENT_STUDENT_DASHBOARD_DATA"
);
export const listStudentAttemptDashboardData = createAction("LIST_STUDENT_ATTEMPT_DASHBOARD_DATA");
export const clearStudentAttemptDashboardData = createAction(
  "CLEAR_STUDENT_ATTEMPT_DASHBOARD_DATA"
);

//Deployment Dashboard

export const getFacultyDashboardSuccess = createAction("GET_FACULTY_DASHBOARD_SUCCESS");

export const getFacultyDashboard = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = getUserCurrentAccount(state);
    const selector = "placements";
    dispatch(request({ selector, operation: OPERATIONS.GET, resetData: true }));

    try {
      // Get all deployments
      const deploymentsResponse = await callGetDeploymentsAPI({ accountId });
      const nonDraftDeployments = deploymentsResponse.data.rows.filter((d) => d.status !== "Draft");
      const deploymentIds = nonDraftDeployments.map((d) => d.id);
      const getAllDeploymentsResponse = await Promise.all(
        deploymentIds.map(async (deploymentId) => {
          return callGetDeploymentDashboardAPI({ accountId, deploymentId });
        })
      );
      const successfulDeploymentResponses = getAllDeploymentsResponse.filter(
        (r) => r.status === "success"
      );

      // Iterate over response and build a list of placements
      // Placement assessments are nested inside deployment details so make sure to map over those inside the deployment object
      const placements = successfulDeploymentResponses.reduce((deploymentCards, current) => {
        const { assessments, ...deployment } = current.data;
        const addedDeploymentCards = assessments.map((assessment) => {
          return {
            assessmentName: assessment.name,
            medianScore: assessment.median_score,
            studentsCompleted: assessment.student_taken_assessment_cnt,
            totalStudents: Number(assessment.student_in_cohort_cnt),
            percentComplete: assessment.student_taken_assessment_per,
            deploymentId: deployment.deployment_id,
            deploymentStatus: deployment.status,
            deploymentName: deployment.deployment_name,
          };
        });

        return deploymentCards.concat(addedDeploymentCards);
      }, []);

      dispatch(getFacultyDashboardSuccess({ placements }));
    } catch (err) {
      console.log({ err });
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
    }
  };
};

export const getDeploymentDashboardData = ({ deploymentId }) => async (dispatch, getState) => {
  const selector = "deployment";
  dispatch(request({ selector, operation: OPERATIONS.GET, resetData: true }));

  try {
    const state = getState();
    var accountId = getUserCurrentAccount(state);

    let apiResponse = await callGetDeploymentDashboardAPI({ accountId, deploymentId });

    if (apiResponse.status === "success") {
      const data = decorateDeploymentDashboardResponse(apiResponse.data);
      dispatch(listDeploymentDashboardData({ selector, data }));
      return apiResponse;
    } else {
      dispatch(reject({ selector, operation: OPERATIONS.GET, errorItem: apiResponse.data }));
      return apiResponse;
    }
  } catch (e) {
    console.log("Exception", e.stack);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

function decorateDeploymentDashboardResponse(item) {
  return {
    id: item.deployment_id,
    deploymentName: item.deployment_name,
    cohort: item.cohort_name,
    program: item.program_name,
    cohortId: item.cohort_id,
    programId: item.program_id,
    startDate: item.start_date,
    endDate: item.end_date,
    ownerName: item.owner_name,
    ownerEmail: item.owner_email,
    isDraft: item.is_draft,
    status: item.status,
    totalNumberofStudents: item.student_in_deployment_cnt,
    numberOfStudentsCompleted: item.student_in_deployment_complete_cnt,
    numberOfStudentsPending: item.student_in_deployment_pending_cnt,
    percentageOfCompletion: item.student_in_deployment_complete_per,
    assessments: item.assessments.map((a) => ({
      id: a.id,
      name: a.name,
      isActive: a.is_active,
      averageScore: a.average_score,
      medianScore: a.median_score,
      standardDeviation: a.std_deviation_score,
      skewnessScore: a.skewness_score,
      kurtosisScore: a.kurtosis_score,
      totalNumberofStudents: a.student_in_cohort_cnt,
      numberOfStudentsCompleted: a.student_taken_assessment_cnt,
      percentageOfCompletion: a.student_taken_assessment_per,
    })),
    placementSummary: item.placement_summary.map((p) => ({
      courseId: p.id,
      course: p.course,
      numberOfStudents: p.student_count,
    })),
  };
}

export const getFacultyAssessmentDashboardData = ({
  selector,
  deploymentId,
  assessmentId,
  page,
  pageSize = 0,
  userScore,
  account_id,
}) => async (dispatch) => {
  dispatch(requestDashboard());
  await dispatch(
    getFacultyDeploymentAssessmentStudentList({
      selector: "deploymentAssessmentUserList",
      deploymentId,
      assessmentId,
      page,
      pageSize,
      userScore,
      account_id,
    })
  );
  const response = await getFacultyDeploymentAssessmentStats({
    selector,
    deploymentId,
    assessmentId,
    account_id,
  });

  if (response.status === "success" && !isEmpty(response.data)) {
    let max_Y = 1;
    const max_X = response.data.max_questions_cnt;
    let arrData = Array.from({ length: max_X + 1 }, (_, idx) => {
      const graphObj = response.data.x_axis_plot.find((obj) => obj.score === idx);
      if (graphObj) {
        return { studentCount: graphObj.count, score: graphObj.score };
      } else {
        return { studentCount: 0, score: idx };
      }
    });

    const deploymentData = {
      assessmentName: response.data.name,
      deploymentName: response.data.deployment_name,
      status: response.data.status,
      program: response.data.program_name,
      ownerName: response.data.owner_name,
      ownerEmail: response.data.owner_email,
      cohort: response.data.cohort_name,
      startDate: response.data.start_date,
      endDate: response.data.end_date,
    };

    const stateData = {
      stats: [
        { title: response.data.median_score, entry: "MED" },
        { title: response.data.average_score, entry: "AVG" },
        { title: response.data.std_deviation_score, entry: "STD" },
        { title: response.data.skewness_score, entry: "SKW" },
        { title: response.data.kurtosis_score, entry: "KRT" },
      ],
      completion: {
        completed: response.data.student_taken_assessment_cnt,
        outOf: response.data.student_in_cohort_cnt,
        completionPercent: response.data.student_taken_assessment_per,
      },
      scoreDistribution: {
        graphData: {
          datasets: arrData,
        },
      },
    };

    dispatch(assessmentStats(stateData));
    dispatch(listDeploymentDashboardData({ selector: "deployment", data: deploymentData }));
    dispatch(
      listDeploymentDashboardData({
        selector: "topicBreakdown",
        data: response.data.topic_breakdown,
      })
    );
  }
};

export const getFacultyDeploymentAssessmentStudentList = ({
  selector,
  deploymentId,
  assessmentId,
  page = 1,
  pageSize = 0,
  userScore,
  account_id,
  appendRecords = false,
}) => async (dispatch) => {
  dispatch(request({ selector, operation: OPERATIONS.GET }));
  try {
    const response = await getFacultyDeploymentStudentList({
      deploymentId,
      assessmentId,
      page,
      pageSize,
      userScore,
      account_id,
    });

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        const studentData = response.data.users.map((user) => {
          return {
            studentId: user.id,
            id: user.user_name,
            name: user.student_name,
            numberOfAttempts: user.no_of_attempts,
            latestScore: user.latest_score,
            latestAttempt: user.latest_attempt,
            durationOfLatest: user.latest_duration,
            endTime: user.end_time,
            topScore: user.top_score,
          };
        });

        const data = {
          users: [...studentData],
          ...pick("total_count", response.data),
        };
        dispatch(
          facultyDeploymentAssessmentStudentList({
            data,
            selector,
            page,
            count: response.data.total_count,
            operation: OPERATIONS.GET,
            implicit: true,
            appendRecords,
          })
        );
      }
    } else {
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.GET,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
  }
};

//Deployment Assessment Student Dashboard

export const getAssessmentStudentDashboardData = ({
  deploymentId,
  assessmentId,
  studentId,
}) => async (dispatch, getState) => {
  const selector = "student";
  try {
    dispatch(request({ selector, operation: OPERATIONS.GET, resetData: true }));

    const state = getState();
    var accountId = getUserCurrentAccount(state);

    let apiResponse = await callGetAssessmentStudentDashboardAPI({
      accountId,
      deploymentId,
      assessmentId,
      studentId,
    });

    if (apiResponse.status === "success") {
      const data = decorateAssessmentStudentDashboardResponse(apiResponse.data);
      const top_score = Math.max.apply(
        Math,
        data.attempts.map(function(o) {
          return o.scorePercentage;
        })
      );
      dispatch(
        listAssessmentStudentDashboardData({
          selector,
          data: { ...data, topScorePercentage: top_score },
        })
      );
      return apiResponse;
    } else {
      dispatch(reject({ selector, operation: OPERATIONS.GET, errorItem: apiResponse.data }));
      return apiResponse;
    }
  } catch (e) {
    console.log(e.stack);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

function decorateAssessmentStudentDashboardResponse(item) {
  return {
    id: item.user_name,
    name: item.student_name,
    email: item.email,
    deploymentId: item.deployment_id,
    deploymentName: item.deployment_name,
    assessmentSeriesId: item.assessment_series_id,
    assessmentName: item.assessment_name,
    latestScorePercentage: item.latest_score_perc,
    totalAttemptCount: item.total_attempt_cnt,
    latestAttemptDate: item.latest_attempt_date,
    latestDuration: item.latest_duration,
    attempts: item.attempts.map((a) => ({
      attemptNumber: a.attempt_number,
      attemptId: a.attempt_id,
      assessmentId: a.assessment_id,
      score: a.score,
      totalScore: a.total_score,
      scorePercentage: a.score_perc,
      versionNumber: a.version_number,
      date: a.attempt_date,
      duration: a.attempt_duration,
    })),
  };
}

//Deployment Assessment Student Attempt Dashboard

export const getStudentAttemptDashboardData = ({ assessmentId, attemptId }) => async (
  dispatch,
  getState
) => {
  const selector = "studentAttempt";
  try {
    dispatch(request({ selector, operation: OPERATIONS.GET, resetData: true }));

    const state = getState();
    var accountId = getUserCurrentAccount(state);

    let apiResponse = await callGetStudentAttemptDashboardAPI({
      accountId,
      assessmentId,
      attemptId,
    });

    if (apiResponse.status === "success") {
      const data = decorateStudentAttemptDashboardResponse(apiResponse.data);
      dispatch(listStudentAttemptDashboardData({ selector, data }));
      return apiResponse;
    } else {
      dispatch(reject({ selector, operation: OPERATIONS.GET, errorItem: apiResponse.data }));
      return apiResponse;
    }
  } catch (e) {
    console.log(e.stack);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

function decorateStudentAttemptDashboardResponse(item) {
  return {
    attemptId: item.attempt_id,
    assessmentName: item.assessment_name,
    deploymentName: item.deployment_name,
    studentName: item.student_name,
    email: item.email,
    userName: item.user_name,
    score: item.score,
    attemptDate: item.attempt_date,
    attemptDuration: item.attempt_duration,
    scorePercent: item.total_score,
    attemptNumber: item.attempt_number,
    versionNumber: item.version_number,
    unansweredQuestionsCount: item.unanswered_questions_count,
    correctQuestionsCount: item.correct_questions_count,
    incorrectQuestionsCount: item.incorrect_questions_count,
    questions: item.questions.map((q) => ({
      id: q.question_id,
      layout: q.layout,
      data: q.data.map((qd) => ({
        src: qd.src,
        text: qd.text,
        type: qd.type,
      })),
      answers: orderBy(
        q.answers.map((a) => ({
          id: a.id,
          isCorrect: a.is_correct,
          isSelected: a.is_selected,
          layout: a.layout,
          data: a.data.map((ad) => ({
            src: ad.src,
            text: ad.text,
            type: ad.type,
          })),
          displayOrder: a.display_order,
        })),
        "displayOrder",
        "asc"
      ),
      isAttempted: q.isAttempted,
      isCorrect: q.is_answered,
    })),
    topicBreakdown: item.topic_breakdown.map((t) => ({
      id: t.id,
      topicName: t.topic,
      studentPerformance: t.student_performance,
      classPerformance: t.class_performance,
    })),
  };
}

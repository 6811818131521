/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import DocumentTitle from "react-document-title";

import PrivateRoute from "components/PrivateRoute";
import ModalManager from "components/modal/ModalManager";
import Home from "components/views/Home";
import Login from "components/views/Login";
import Notifier from "components/Notifier";
import { ZendeskForm } from "components/ZendeskForm";
import { appEnv } from "utilities/API.js";

const App = (props) => {
  const { loggedIn, logOutClicked, location, user } = props;
  const referrer =
    location.state?.referrer && !logOutClicked
      ? location.state?.referrer + location.search
      : "/" + location.search;

  const posthog = usePostHog();
  const [isIdentified, setIsIdentified] = useState(false);

  useEffect(() => {
    if (user.username && !isIdentified) {
      try {
        posthog?.identify(
          user.username,
          {},
          {
            email: user.email,
            name: user.name,
            role: user.role,
            accountId: user.accountId,
            accountName: user.accountName,
            environment: appEnv,
          }
        );

        posthog?.group("account", user.accountId, {
          type: "account",
          id: user.accountId,
          name: user.accountName,
          environment: appEnv,
        });

        setIsIdentified(true);
      } catch (error) {
        console.log("PostHog - identify/group", error);
      }
    }
  }, [posthog, isIdentified, user]);

  useEffect(() => {
    if (logOutClicked) {
      try {
        posthog?.reset();
      } catch (error) {
        console.log("PostHog - reset", error);
      }
    }
  }, [logOutClicked, posthog]);

  return (
    <div>
      <DocumentTitle title="Stemify">
        <Switch>
          <Route
            path="/login"
            render={() => {
              return loggedIn ? <Redirect to={referrer} /> : <Login />;
            }}
          />
          <PrivateRoute path="/" component={Home} />
        </Switch>
      </DocumentTitle>

      <Notifier />
      <ModalManager />
      <ZendeskForm />
    </div>
  );
};

export default withRouter(
  connect(
    (state) => ({
      user: state?.user,
      loggedIn: state?.user?.loggedIn,
      isSSOLogin: state?.user?.isSSOLogin,
      logOutClicked: state?.user?.logOutClicked,
    }),
    {}
  )(App)
);

import { refreshToken } from "services/login";
import { updateAuthStore, getAccessToken } from "./localStore";
import { submitLogout, noNetworkFound, networkRestored } from "store/state/user/actions";
import { getDebounced } from "utilities/commonFunctions";
import store from "store/store";
import { RETRY_API_ERROR_CODES } from "constants/api";
import {
  loginPath,
  resetPasswordPath,
  confirmForgetPasswordPath,
  firstSigninResetPath,
  logoutPath,
  refreshTokenPath,
  contentType,
  apiBaseUrl,
  apiLocalUrl,
  apiProdUrl,
} from "constants/api";

const createUrl = (env) => {
  return apiBaseUrl + env;
};

const createProdUrl = (env) => {
  return apiProdUrl + env;
};

var appEnvironment = "DEV";
const apiUrl = ((createUrl) => {
  const host = window && window.location.host;

  if (process.env.REACT_APP_LOCAL_API_ENV) {
    console.log("LOCAL API ENVIRONMENT:", process.env.REACT_APP_LOCAL_API_ENV);

    if (process.env.REACT_APP_LOCAL_API_ENV === "local") {
      return apiLocalUrl;
    }

    if (process.env.REACT_APP_LOCAL_API_ENV === "prod") {
      return createProdUrl(process.env.REACT_APP_LOCAL_API_ENV);
    }

    return createUrl(process.env.REACT_APP_LOCAL_API_ENV);
  }

  switch (host.split(".")[0]) {
    case "sandbox":
      return createUrl("sandbox");

    case "dev":
    case "localhost:3000":
      return createUrl("dev");

    case "test":
      return createUrl("test");

    case "staging":
      return createUrl("staging");

    case "demo":
      return createUrl("sav");

    case "app":
      appEnvironment = "PROD";
      return createProdUrl("prod");

    default:
      return;
  }
})(createUrl);

const appEnv = appEnvironment;

const login = async ({ username, password }) => {
  let base64 = new Buffer(username + ":" + password).toString("base64");
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: loginPath,
    method: "post",
    body: JSON.stringify({
      Username: username,
      Password: password,
    }),
    headers: {
      Authorization: "Basic " + base64,
      "Content-Type": "application/json",
    },
  });
};

const changePassword = async ({ username, newPassword, confirmationCode }) => {
  let base64 = new Buffer(username + ":" + newPassword).toString("base64");
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: confirmForgetPasswordPath,
    method: "post",
    body: JSON.stringify({ confirmationCode }),
    headers: {
      Authorization: "Basic " + base64,
      "Content-Type": "application/json",
    },
  });
};

const resetPassword = async ({ email }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: resetPasswordPath,
    method: "post",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const firstSigninResetPassword = async ({ session, username, password }) => {
  let base64 = new Buffer(username + ":" + password).toString("base64");
  let heade = {
    Session: session,
    Authorization: "Basic " + base64,
    "Content-Type": "application/json",
  };
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: firstSigninResetPath,
    method: "post",
    body: {
      Session: session,
      Username: username,
      Password: password,
    },
    headers: heade,
  });
};

const logout = async () => {
  const accessToken = getAccessToken();
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: logoutPath,
    method: "post",
    body: JSON.stringify({
      accessToken: accessToken,
    }),
    headers: {
      Authorization: accessToken,
      "Content-Type": contentType,
    },
  });
};

const debouncedRefreshToken = getDebounced(() => {
  refreshToken();
}, 10000);

const fetchAPICall = async ({ baseURI, resourcePath, method, headers, body, retries = 3 }) => {
  let ret = retries - 1;
  try {
    const apiResponse = await fetch(`${baseURI}${resourcePath}`, {
      mode: "cors",
      method,
      headers,
      body,
    });

    // if the api call being made isn't one of these paths
    // then debounce it
    if (
      ![logoutPath, loginPath, refreshTokenPath, firstSigninResetPath, resetPasswordPath].includes(
        resourcePath
      )
    ) {
      debouncedRefreshToken();
    }

    const response = await apiResponse.json();
    if (response && response.status) {
      if (response.status == "error") {
        if (response.data.errorCode == 155) {
          // When we get this unauthorized error code, log the user out
          submitLogout()(store.dispatch);
        } else if (retries > 0 && RETRY_API_ERROR_CODES.includes(response.status.errorCode)) {
          return fetchAPICall({ baseURI, resourcePath, method, headers, body, retries: ret });
        }
      }
    } else {
      if (retries > 0) {
        return setTimeout(() => {
          fetchAPICall({ baseURI, resourcePath, method, headers, body, retries: ret });
        }, 3000);
      }
    }
    return response;
  } catch (e) {
    if (retries > 0) {
      return setTimeout(() => {
        return fetchAPICall({ baseURI, resourcePath, method, headers, body, retries: ret });
      }, 3000);
    } else {
      store.dispatch(noNetworkFound());
      console.log(`error using fetch to make a request to ${baseURI}${resourcePath}`, e);
    }
  }
};

const convertToCamelCase = (key) => {
  return key
    .split("_")
    .map((segment, index) =>
      index > 0 ? `${segment[0].toUpperCase()}${segment.substring(1)}` : segment
    )
    .join("");
};

const convertToUnderscore = (key) => {
  return [...key.matchAll(/([a-z]+)|([A-Z][a-z]+|[A-Z]+(?![a-z]))/g)]
    .map((match) => match[0].toLowerCase())
    .join("_");
};

const decorateObjectKeys = (object, decorator, exclude = []) =>
  ["[object Object]", "[object Array]"].includes(Object.prototype.toString.call(object))
    ? Object.entries(object)
        .map((entry) => {
          const valueTypes = {
            "[object Object]": (entryValue) => decorateObjectKeys(entryValue, decorator),
            "[object Array]": (entryValue) =>
              entryValue.map((arrayElement) => decorateObjectKeys(arrayElement, decorator)),
          };
          const valueType = Object.prototype.toString.call(entry[1]);
          const value = valueTypes[valueType] ? valueTypes[valueType](entry[1]) : entry[1];

          return !exclude.includes(entry[0]) ? [decorator(entry[0]), value] : [entry[0], entry[1]];
        })
        .reduce(
          (decoratedObject, entry) => ({
            ...decoratedObject,
            [entry[0]]: entry[1],
          }),
          {}
        )
    : object;

const convertKeysToCamelCase = (object, exclude) =>
  decorateObjectKeys(object, convertToCamelCase, exclude);

const convertKeysToUnderscore = (object, exclude) =>
  decorateObjectKeys(object, convertToUnderscore, exclude);

export {
  apiUrl,
  login,
  changePassword,
  resetPassword,
  firstSigninResetPassword,
  logout,
  fetchAPICall,
  getDebounced,
  appEnv,
  convertKeysToCamelCase,
  convertKeysToUnderscore,
};
